'use client';
import * as amplitude from '@amplitude/analytics-browser';

const getBrowserDetails = () => {
  if (typeof window !== 'undefined' && navigator) {
    const details = {
      device: {
        type: /Mobi|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
        userAgent: navigator.userAgent,
      },
      browser: {
        name: getBrowserName(),
        version: getBrowserVersion(),
        renderingEngine: getRenderingEngine(),
      },
      os: {
        name: getOSName(),
        version: getOSVersion(),
      },
      performance: {
        pageLoadTime: calculatePageLoadTime(),
      },
      localization: {
        language: navigator.language,
        languages: navigator.languages,
      },
    };
    return details;
  }
  return {};
};

const getBrowserName = () => {
  if (navigator.userAgent.includes('Chrome')) return 'Chrome';
  if (navigator.userAgent.includes('Firefox')) return 'Firefox';
  if (
    navigator.userAgent.includes('Safari') &&
    !navigator.userAgent.includes('Chrome')
  )
    return 'Safari';
  if (navigator.userAgent.includes('Edge')) return 'Edge';
  if (
    navigator.userAgent.includes('MSIE') ||
    navigator.userAgent.includes('Trident')
  )
    return 'Internet Explorer';
  return 'Unknown';
};

const getBrowserVersion = () => {
  const match = navigator.userAgent.match(
    /(Chrome|Firefox|Safari|Edge|MSIE|rv:)[\s\/]?([\d.]+)/,
  );
  return match ? match[2] : 'Unknown';
};

const getRenderingEngine = () => {
  if (navigator.userAgent.includes('WebKit')) return 'WebKit';
  if (
    navigator.userAgent.includes('Gecko') &&
    !navigator.userAgent.includes('WebKit')
  )
    return 'Gecko';
  if (navigator.userAgent.includes('Trident')) return 'Trident';
  return 'Unknown';
};

const getOSName = () => {
  if (navigator.userAgent.includes('Windows')) return 'Windows';
  if (navigator.userAgent.includes('Mac')) return 'MacOS';
  if (
    navigator.userAgent.includes('X11') ||
    navigator.userAgent.includes('Linux')
  )
    return 'Linux';
  if (/Android/.test(navigator.userAgent)) return 'Android';
  if (/iPhone|iPad|iPod/.test(navigator.userAgent)) return 'iOS';
  return 'Unknown';
};

const getOSVersion = () => {
  const match = navigator.userAgent.match(
    /(Windows NT|Mac OS X|Android|CPU iPhone OS|CPU OS|Linux)[\s\/]?([\d._]+)/,
  );
  return match ? match[2].replace(/_/g, '.') : 'Unknown';
};

const calculatePageLoadTime = () => {
  if (performance.timing) {
    return performance.timing.loadEventEnd - performance.timing.navigationStart;
  }
  return 'Unknown';
};

const trackEvent = (eventName: string, eventProperties: any) => {
  const browserDetails = getBrowserDetails();
  amplitude.logEvent(eventName, {
    ...eventProperties,
    browserDetails,
  });
};

export default trackEvent;
