import { useUser } from '@auth0/nextjs-auth0';
import { Box, Button, CheckSwitch } from '@deckee/deck-hand';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, {
  ComponentProps,
  HTMLAttributes,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import styled, { useTheme } from 'styled-components';
import useLoloMember from '../hooks/use-lolo-member';
import useSoundAlarm from '../hooks/use-sound-alarm';
import trackEvent from '../lib/amplitude';
import {
  createLoginUrl,
  createLogOutUrl,
  createProfileUrl,
} from '../lib/url-helper';
import { HEADER_ACTIVE_NAV_INDICATOR_SIZE } from '../lib/utils';
import Can from './can';
import Logo from './logo';
import NavMenuDropdown from './nav-menu-dropdown';
import SoundPlayer from './sound-player';
import Audio from './svg/audio';
import Chart from './svg/chart';
import Logout from './svg/logout';
import NoAudio from './svg/no-audio';
import PersonAlt from './svg/person-alt';
import Settings from './svg/settings';
import WalkieTalkie from './svg/walkie-talkie';
import * as amplitude from '@amplitude/analytics-browser';
import HyperDX from '@hyperdx/browser';

const HEADER_HEIGHT = 66;

const StyledHeader = styled.header`
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  height: ${HEADER_HEIGHT}px;

  position: sticky;
  top: 0px;
  z-index: 9999999;
  box-shadow: -2px -8px 5px 5px black;
  overflow: visible;

  @media print {
    display: none;
  }
`;

const Nav = styled.nav<HTMLAttributes<HTMLElement>>`
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
`;

const NavLink = (
  props: ComponentProps<typeof Link> & { selected?: boolean },
) => {
  const { children, ...restProps } = props;

  return (
    <StyledNavLink
      onClick={() => {
        trackEvent('header-nav-click', { path: props.href });
      }}
      $selected={props.selected}
    >
      <Link {...restProps} passHref legacyBehavior>
        <StyledAnchor>{children}</StyledAnchor>
      </Link>
    </StyledNavLink>
  );
};

interface HTMLElementAndSelectedProps<T> extends HTMLAttributes<T> {
  $selected?: boolean;
}

const StyledAnchor = styled.a`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledNavLink = styled.div<HTMLElementAndSelectedProps<HTMLSpanElement>>`
  text-decoration: none;
  font-family: ${({ theme }) => theme.typeface.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.336332px;
  text-wrap: nowrap;
  height: 100%;

  &,
  & > * {
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ $selected }) => ($selected ? 'bold' : 'normal')};
  }
`;

const UnorderedList = styled.ul`
  display: flex;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: ${({ theme }) => theme.space[2.5]}px;
  padding-bottom: ${({ theme }) => theme.space[2.5]}px;
  align-items: stretch;
  height: ${HEADER_HEIGHT}px;
  line-height: ${HEADER_HEIGHT}px;
`;

const HeaderButtonContainer = ({
  selected,
  children,
}: {
  selected?: boolean;
  children: ReactElement;
}) => (
  <StyledHeaderButtonContainer $selected={selected}>
    {React.cloneElement(children, { selected })}
  </StyledHeaderButtonContainer>
);

const StyledHeaderButtonContainer = styled.li<
  HTMLElementAndSelectedProps<HTMLLIElement>
>`
  align-self: center;
  display: flex;
  align-items: center;
  margin-right: 2rem;
  height: ${HEADER_HEIGHT}px;
  line-height: ${HEADER_HEIGHT}px;
  border-top: ${HEADER_ACTIVE_NAV_INDICATOR_SIZE} solid transparent;
  border-bottom: ${HEADER_ACTIVE_NAV_INDICATOR_SIZE} solid transparent;
  border-bottom-color: ${({ theme, $selected }) =>
    $selected ? theme.colors.primary : 'transparent'};

  color: ${({ theme }) => theme.colors.black};

  &:nth-last-child(2) {
    margin-right: auto;
  }

  &:last-child {
    margin-right: 0;
  }
`;

// Function to request permission for notifications
function requestNotificationPermission() {
  navigator.permissions.query({ name: 'notifications' });
  if (Notification.permission !== 'granted') {
    Notification.requestPermission().then((permission) => {});
  }
}

// Function to show a notification with sound
function showNotification(title, message) {
  if (Notification.permission === 'granted') {
    new Notification(title, {
      body: message,
      silent: false,
      // @ts-ignore
      sound: 'SAR-Alarm.mp3',
    });
  } else {
    alert('Please enable notifications first.');
  }
}

const Header = (): React.ReactElement => {
  const theme = useTheme();
  const router = useRouter();
  const { user, isLoading } = useUser();
  const { member, check } = useLoloMember();
  const { soundAlarm } = useSoundAlarm();
  const [alarmEnabled, setAlarmEnabled] = useState(false);

  useEffect(() => {
    // If hostname doesn't contain the word staging, we are on production
    if (!window.location.hostname.includes('staging')) {
      if (process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY_PRODUCTION) {
        amplitude.init(
          process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY_PRODUCTION,
          member?.id || undefined,
          { minIdLength: 1 },
        );
      }
      // Initialise hyperDX
      if (process.env.NEXT_PUBLIC_HYPERDX_API_KEY) {
        const OTEL_SERVICE_NAME = 'search-rescue-dashboard-production';
        const OTEL_RESOURCE_ATTRIBUTES = 'deployment.environment=production';
        // Set the environment variable, can't see how you can pass it through?
        process.env.OTEL_RESOURCE_ATTRIBUTES = OTEL_RESOURCE_ATTRIBUTES;
        HyperDX.init({
          apiKey: process.env.NEXT_PUBLIC_HYPERDX_API_KEY,
          service: OTEL_SERVICE_NAME,
          tracePropagationTargets: [/lolo.deckee.com\/api/i], // Set to link traces from frontend to backend requests
          consoleCapture: false,
          advancedNetworkCapture: false,
          instrumentations: {
            fetch: {
              ignoreUrls: [
                new RegExp('mapbox|amplitude|google-analytics', 'g'),
              ],
            },
            xhr: {
              ignoreUrls: [
                new RegExp('mapbox|amplitude|google-analytics', 'g'),
              ],
            },
          },
        });
      }
    } else {
      if (process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY_STAGING) {
        amplitude.init(
          process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY_STAGING,
          member?.id || undefined,
          { minIdLength: 1 },
        );
      }
      if (process.env.NEXT_PUBLIC_HYPERDX_API_KEY) {
        const OTEL_SERVICE_NAME = 'search-rescue-dashboard-staging';
        const OTEL_RESOURCE_ATTRIBUTES = 'deployment.environment=staging';
        // Set the environment variable, can't see how you can pass it through?
        process.env.OTEL_RESOURCE_ATTRIBUTES = OTEL_RESOURCE_ATTRIBUTES;
        HyperDX.init({
          apiKey: process.env.NEXT_PUBLIC_HYPERDX_API_KEY,
          service: OTEL_SERVICE_NAME,
          tracePropagationTargets: [/lolo.deckee.com\/api/i], // Set to link traces from frontend to backend requests
          consoleCapture: false,
          advancedNetworkCapture: false,
          instrumentations: {
            fetch: {
              ignoreUrls: [
                new RegExp('mapbox|amplitude|google-analytics', 'g'),
              ],
            },
            xhr: {
              ignoreUrls: [
                new RegExp('mapbox|amplitude|google-analytics', 'g'),
              ],
            },
          },
        });
      }
    }
  }, [member]);

  const handleToggleEnableSound = () => {
    trackEvent('header-alarm-toggle-click', { alarm_enabled: !alarmEnabled });
    if (!alarmEnabled) {
      requestNotificationPermission();
    }
    setAlarmEnabled(!alarmEnabled);
  };
  const handleNewLogOnBtnClick = () => {
    trackEvent('header-log-new-trip-click', {});
    router.push({
      pathname: '/trips/create',
      query: {},
    });
  };

  useEffect(() => {
    if (alarmEnabled && soundAlarm) {
      showNotification(
        'A trip has transitioned into an incident state!',
        'View the incident in the dashboard for more information',
      );
    }
  }, [alarmEnabled, soundAlarm]);

  const userMenuOptions = [
    check('reports:access') && {
      label: 'Reports',
      value: '/reports',
      icon: <Chart size={24} />,
    },
    check('users:list') && {
      label: 'Admin',
      value: '/admin',
      icon: <Settings size={24} />,
    },
    member && {
      label: 'Edit your profile',
      description: user?.email,
      value: createProfileUrl(),
      icon: <PersonAlt size={24} />,
    },
    {
      label: 'Provide feedback',
      value: 'https://deckee.com/lolo-feedback',
      isExternal: true,
      icon: <WalkieTalkie size={24} color={theme.colors.secondary} />,
    },
    {
      label: 'Logout',
      value: createLogOutUrl(),
      icon: <Logout size={24} />,
    },
  ].filter((option) => !!option);

  return (
    <StyledHeader>
      <Nav aria-label="Main navigation">
        <UnorderedList>
          <HeaderButtonContainer>
            <NavLink href="/">
              <Logo height={40} marginTop="0px" />
            </NavLink>
          </HeaderButtonContainer>
          {!isLoading &&
            (user ? (
              <>
                <Can
                  perform="trips:list"
                  yes={() => (
                    <>
                      <HeaderButtonContainer
                        selected={
                          window.location.href.split('/')[3] === '' ||
                          window.location.href.split('/')[3] === 'trips'
                        }
                      >
                        <NavLink href="/">Dashboard</NavLink>
                      </HeaderButtonContainer>
                    </>
                  )}
                />
                <Can
                  perform="trips:history"
                  yes={() => (
                    <>
                      <HeaderButtonContainer
                        selected={
                          window.location.href.split('/')[3] === 'history'
                        }
                      >
                        <NavLink href="/history">History</NavLink>
                      </HeaderButtonContainer>
                    </>
                  )}
                />
                <Can
                  perform="groups:list"
                  yes={() => (
                    <>
                      <HeaderButtonContainer
                        selected={
                          window.location.href.split('/')[3] === 'groups'
                        }
                      >
                        <NavLink href="/groups">Groups</NavLink>
                      </HeaderButtonContainer>
                    </>
                  )}
                />
                <Can
                  perform="contact-log:list"
                  yes={() => (
                    <>
                      <HeaderButtonContainer
                        selected={
                          window.location.href.split('/')[3] === 'contact-log'
                        }
                      >
                        <NavLink href="/contact-log">Contact log</NavLink>
                      </HeaderButtonContainer>
                    </>
                  )}
                />

                <HeaderButtonContainer>
                  <NavMenuDropdown label="More" options={userMenuOptions} />
                </HeaderButtonContainer>

                <Box
                  display="flex"
                  alignItems="stretch"
                  marginLeft="auto"
                  mr="2"
                  minWidth="150px"
                  lineHeight="normal"
                >
                  <Button
                    type="button"
                    outlined
                    onClick={handleToggleEnableSound}
                    size="small"
                    wide
                  >
                    {alarmEnabled ? (
                      <Audio size={24} color={theme.colors.primary} />
                    ) : (
                      <NoAudio size={24} color={theme.colors.primary} />
                    )}
                    <Box ml="2">Alarm is {alarmEnabled ? 'on' : 'off'}</Box>
                  </Button>
                  {alarmEnabled && soundAlarm && (
                    <SoundPlayer url="/SAR-Alarm.mp3" />
                  )}

                  <Can
                    perform="trips:create"
                    yes={() => (
                      <Button
                        ml="2"
                        size="small"
                        maxWidth="140px"
                        wide
                        onClick={handleNewLogOnBtnClick}
                        variant="primary"
                      >
                        Log new trip
                      </Button>
                    )}
                  />
                </Box>
              </>
            ) : (
              <HeaderButtonContainer>
                <NavLink href={createLoginUrl()}>Login</NavLink>
              </HeaderButtonContainer>
            ))}
        </UnorderedList>
      </Nav>
    </StyledHeader>
  );
};

export default Header;
