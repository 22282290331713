import React from 'react';

const ICON1 = `m211 196c0 8.284 6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15h-120c-8.284 0-15 6.716-15 15z`;
const ICON2 = `m346 241h-120c-8.284 0-15 6.716-15 15s6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15z`;
const ICON3 = `m226 0c-8.284 0-15 6.716-15 15v106h-15c-24.813 0-45 20.187-45 45v75h-45c-8.284 0-15 6.716-15 15v90c0 8.284 6.716 15 15 15h45v106c0 24.813 20.187 45 45 45h150c24.813 0 45-20.187 45-45v-117.459l28.417-56.833c1.042-2.083 1.583-4.379 1.583-6.708v-120c0-24.813-20.187-45-45-45h-135v-106c0-8.284-6.716-15-15-15zm-105 331v-60h30v60zm270-165v116.459l-28.417 56.833c-1.041 2.083-1.583 4.379-1.583 6.708v121c0 8.271-6.729 15-15 15h-150c-8.271 0-15-6.729-15-15v-301c0-8.271 6.729-15 15-15h180c8.271 0 15 6.729 15 15z`;

const WalkieTalkie = ({
  size,
  color = '#484848',
  transform = 'none',
  onClick,
  cursor = 'pointer',
}: {
  size: number;
  color?: string;
  transform?: string;
  onClick?: any;
  cursor?: string;
}): React.ReactElement => (
  <svg
    height={size}
    viewBox="0 0 512 512"
    style={{
      cursor,
      fill: color,
      stroke: 'none',
      transform,
    }}
    onClick={onClick}
  >
    <path d={ICON1} />
    <path d={ICON2} />
    <path d={ICON3} />
  </svg>
);

WalkieTalkie.defaultProps = {
  color: null,
  transform: 'none',
  onClick: null,
  cursor: 'pointer',
};

export default WalkieTalkie;
