import ServiceError from './serviceError';

export class FloatPlanCreationError extends ServiceError {
  constructor(message: string) {
    super(message, 'FLOAT_PLAN_CREATION_ERROR', 400);
  }
}

export class InvalidTripPlanId extends ServiceError {
  constructor(message: string) {
    super(message, 'INVALID_TRIP_PLAN_ID', 400);
  }
}

export class MissingUser extends ServiceError {
  constructor(message: string) {
    super(message, 'MISSING_USER', 401);
  }
}

export class UserExistsError extends ServiceError {
  constructor(message: string) {
    super(message, 'USER_EXISTS_ERROR', 409);
  }
}

export class UserBlocked extends ServiceError {
  static errorCode = 'USER_BLOCKED';

  constructor(message: string) {
    super(message, UserBlocked.errorCode, 403);
  }
}

export class UserDeletionError extends ServiceError {
  constructor(message: string) {
    super(message, 'USER_DELETION_ERROR', 500);
  }
}

/** For when you get a result that should never happen. e.g. a GraphQL response is missing the root object you're querying. */
export class InvalidResponse extends ServiceError {
  constructor(message: string) {
    super(message, 'INVALID_RESPONSE', 500);
  }
}

export class NoteCreationError extends ServiceError {
  constructor(message: string) {
    super(message, 'NOTE_CREATION_ERROR', 500);
  }
}

export class GetNotesError extends ServiceError {
  constructor(message: string) {
    super(message, 'GET_NOTES_ERROR', 500);
  }
}

export class UserForbidden extends ServiceError {
  constructor(message: string) {
    super(message, 'USER_FORBIDDEN', 403);
  }
}

export class LastKnownLocationTripEndedError extends ServiceError {
  constructor(message: string) {
    super(message, 'LAST_KNOWN_LOCATION_TRIP_ENDED_ERROR', 400);
  }
}
