import { IconWithTextLockup } from '@deckee/deck-hand';
import Link from 'next/link';
import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ComponentProps,
  HTMLAttributes,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import styled, { useTheme } from 'styled-components';
import { debounce } from 'throttle-debounce';
import trackEvent from '../lib/amplitude';
import { HEADER_ACTIVE_NAV_INDICATOR_SIZE } from '../lib/utils';
import IconCircleWrapper from './IconCircleWrapper/IconCircleWrapper';

interface DropdownContentProps extends HTMLAttributes<HTMLElement> {
  showDropDown: boolean;
}

const DropdownDiv = styled.div<HTMLAttributes<HTMLDivElement>>`
  position: relative;
  height: 100%;
`;

const DropdownContent = styled.div<DropdownContentProps>`
  line-height: initial;
  width: max-content;

  display: ${({ showDropDown }) => (showDropDown ? 'block' : 'none')};
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.defaultRadius};
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  margin-top: ${HEADER_ACTIVE_NAV_INDICATOR_SIZE};

  > .dropdown-item {
    padding: ${({ theme }) => `${theme.space[2]}px ${theme.space[3]}px`};
  }

  > .dropdown-item:first-child {
    padding-top: ${({ theme }) => theme.space[2.5]}px;
  }
  > .dropdown-item:last-child {
    padding-bottom: ${({ theme }) => theme.space[2.5]}px;
  }

  .dropdown-item:hover {
    background-color: #dbdde2;
  }
  // Stop the children background hovers from escaping the corners
  overflow: clip;
`;

const DropdownToggleButton = styled.button<
  ButtonHTMLAttributes<HTMLButtonElement>
>`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${({ theme }) => theme.colors.black};
  border: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.336332px;
  padding-top: auto;
`;

const DropdownItem = (
  props: ComponentProps<IconWithTextLockup> & {
    href: string;
    target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];
  },
): React.ReactElement => {
  const { href, target, ...restProps } = props;
  return (
    <StyledDropdownItem className="dropdown-item" href={href} target={target}>
      <IconWithTextLockup {...restProps} />
    </StyledDropdownItem>
  );
};

const StyledDropdownItem = styled.a<AnchorHTMLAttributes<HTMLAnchorElement>>`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
  display: block;
`;

const NavMenuDropdown = ({
  label,
  options,
}: {
  label: string;
  options: {
    value: string;
    label: string;
    icon: ReactNode;
    description?: string;
    isExternal?: boolean;
  }[];
}): React.ReactElement => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  /** Wait a short time before closing the dropdown when the mouse is no longer hovering on it. */
  const debounceCloseDropdown = useMemo(
    () =>
      debounce(250, (open: boolean) => {
        if (!open) {
          setDropdownOpen(false);
        }
      }),
    [],
  );

  const openDropdown = (open: boolean) => {
    if (open) {
      setDropdownOpen(true);
    }
    debounceCloseDropdown(open);
  };

  const theme = useTheme();

  return (
    <DropdownDiv
      onMouseEnter={() => openDropdown(true)}
      onMouseLeave={() => openDropdown(false)}
    >
      <DropdownToggleButton onClick={toggleDropdown}>
        {label}
      </DropdownToggleButton>

      <DropdownContent showDropDown={isDropdownOpen}>
        {options.map((option) =>
          // The Link component doesn't work with target="_blank" so render a plain anchor tag if the link is external. I think Next.js 13 solves this
          option.isExternal ? (
            <DropdownItem
              href={option.value}
              target="_blank"
              key={option.value}
              image={<IconCircleWrapper>{option.icon}</IconCircleWrapper>}
              heading={option.label}
              description={option.description}
              emphasis="heading"
            ></DropdownItem>
          ) : (
            <Link
              href={option.value}
              key={option.value}
              passHref
              legacyBehavior
            >
              <DropdownItem
                image={<IconCircleWrapper>{option.icon}</IconCircleWrapper>}
                heading={option.label}
                description={option.description}
                emphasis="heading"
                onClick={() => {
                  console.log('>>>', option.value);
                  trackEvent('header-nav-more-click', { path: option.value });
                }}
              />
            </Link>
          ),
        )}
      </DropdownContent>
    </DropdownDiv>
  );
};

export default NavMenuDropdown;
